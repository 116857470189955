import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { authSelectors } from '@ucheba/store/auth'
import { profileSelectors, profileThunks } from '@ucheba/store/profile'
import { authPhoneSelectors, authPhoneThunks } from '@ucheba/store/auth/phone'
import { profileRegistrationThunks } from '@ucheba/store/profile/registration'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import {
  authPhoneCodeSelectors,
  authPhoneCodeThunks,
} from '@ucheba/store/auth/phone/code'
import { eventsIdThunks } from '@ucheba/store/events/id'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { UCHEBA_SITE_NAME } from '@ucheba/utils/constants/core'
import { IEventsId } from '@ucheba/store/events/id/types'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import { eventsMyThunks } from '@ucheba/store/events/my'
import { IDialog } from '@ucheba/ui/components/Dialog/types'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { useRouter } from 'next/router'
import {
  parentFormDialogRequestId,
  parentFormDialogSuccessId,
} from '../../../ParentFormDialog/constants'

interface IFormReg {
  name: string
  phone: string
  isParent: string
  classForm: string
  email: string
}

interface IProps {
  id?: number | null
  exam?: IEventsId['exam']
  rubric?: IEventsId['rubric']

  onClickRegistration?: () => void

  /** Например если это попап, то закрыть */
  onAfterSubmitForm?: () => void
}

interface IUseEventBrandingUchebaReg {
  (props: IProps): {
    onSubmit: (values: any) => void
    onPhoneConfirmSubmit: (code: string) => void
    onPhoneConfirmClose: () => void
    phone: string | null | undefined
    needConfirmPhone: boolean
    parentFormDialog: IDialog
    messengersDialog: IDialog
    isLoading: boolean
    initialValues: {
      name: string
      phone: string
      isParent: string
      classForm: {
        id: number
        name: string
      }[]
    }
  }
}

const isSummer = (): boolean => {
  const currentMonth = Number(dayjs().format('M'))

  return currentMonth > 5 && currentMonth < 9
}

const transferGrade = (gradeOrYear: string): string => {
  const currentYear = Number(dayjs().format('YYYY'))
  const currentMonth = Number(dayjs().format('M'))
  const gradeOrYearNumber = Number(gradeOrYear)

  if (isSummer()) {
    return String(currentYear - gradeOrYearNumber)
  }

  return String(currentYear - gradeOrYearNumber + (currentMonth > 5 ? 1 : 0))
}

export const useEventBrandingUchebaReg: IUseEventBrandingUchebaReg = (props) => {
  const { onClickRegistration, id, exam, rubric, onAfterSubmitForm } = props
  const dispatch = useDispatch()
  const router = useRouter()
  const { query } = router

  const isAuth = useSelector(authSelectors.isAuth)
  const profile = useSelector(profileSelectors.entity)
  const store = useStore()
  const parentFormDialog = useDialog(parentFormDialogRequestId + id)
  const messengersDialog = useDialog(parentFormDialogSuccessId + id)

  const initialValues = useMemo(() => {
    return {
      name: '',
      phone: '',
      email: profile?.email || null,
      isParent: 'false',
      classForm: [
        { id: 1, name: '9' },
        { id: 2, name: '10' },
        { id: 3, name: '11' },
      ],
    }
  }, [])

  const [currentFormValues, setCurrentFormValues] = useState<IFormReg | null>(null)

  const [needConfirmPhone, setNeedConfirmPhone] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const patchProfile = useCallback(
    async (values: IFormReg): Promise<any> => {
      const educations =
        values.classForm > '0'
          ? [
              {
                programType: 1000,
                fromYear: Number(transferGrade(values.classForm)),
              },
            ]
          : []

      await dispatch<any>(
        profileThunks.patch({
          data: {
            name: values.name,
            email: values.email,
            isParent: values?.isParent === 'true',
            educations,
            enrolleeInfo: {
              educationLevel: values.classForm < '0' ? 2 : 1,
            },
          },
        })
      )

      await dispatch<any>(profileThunks.get({}))
      return new Promise<void>((res) => {
        setTimeout(() => {
          res()
        }, 10)
      })
    },
    [dispatch]
  )

  const registerProfile = useCallback(
    async (values: IFormReg, phoneCode: string) => {
      const educations =
        values.classForm > '0'
          ? [
              {
                programType: 1000,
                fromYear: Number(transferGrade(values.classForm)),
              },
            ]
          : []

      const visit = getVisitInfo(UCHEBA_SITE_NAME)

      const reqData = {
        name: values?.name,
        phone: values?.phone,
        email: values.email,
        phoneCode,
        isParent: values?.isParent === 'true',
        educations,
        enrolleeInfo: {
          educationLevel: values.classForm < '0' ? 2 : 1,
        },
        visit,
      }

      if (query[EDataKeys.invitationKey]) {
        reqData.inviteCode = query[EDataKeys.invitationKey]
      }

      await dispatch<any>(
        profileRegistrationThunks.fetch({
          data: reqData,
        })
      )
      await dispatch<any>(profileThunks.get({}))
    },
    [dispatch, query]
  )

  const sendRequestCode = useCallback(
    async (values: any, code: string | null): Promise<any> => {
      setIsLoading(true)

      const reqData = {
        phone: values.phone,
        code,
        rememberMe: true,
      }

      if (query[EDataKeys.invitationKey]) {
        reqData.inviteCode = query[EDataKeys.invitationKey]
      }

      const res = await dispatch<any>(
        authPhoneThunks.phone({
          data: reqData,
        })
      )

      setIsLoading(false)

      return res
    },
    [dispatch, query]
  )

  const onSubmit = useCallback(
    async (values) => {
      setCurrentFormValues(values)

      const formPhone = values.phone.replace(/[^\d]/g, '')
      const profilePhone = profile?.phone?.replace(/[^\d]/g, '')

      if (!isAuth || (profilePhone && profilePhone !== formPhone)) {
        await dispatch(authPhoneCodeThunks.sendCode({ data: { phone: values.phone } }))

        const error = authPhoneCodeSelectors.error(store.getState())

        if (!error) {
          setNeedConfirmPhone(true)
        }
      } else {
        await patchProfile(values)
        if (onClickRegistration) {
          onClickRegistration()
        }
      }
    },
    [dispatch, isAuth, onClickRegistration, patchProfile, profile?.phone, store]
  )

  const onPhoneConfirmSubmit = useCallback(
    async (code: string) => {
      const res = await sendRequestCode(currentFormValues, code)

      const error = authPhoneSelectors.error(store.getState())

      if (!currentFormValues) return

      if (!error) {
        if (res.payload) {
          await patchProfile(currentFormValues)
        } else {
          await registerProfile(currentFormValues, code)
        }
        setNeedConfirmPhone(false)

        await dispatch(
          eventsIdThunks.register({
            data: {
              id,
              visit: getVisitInfo(UCHEBA_SITE_NAME),
            },
          })
        )

        if (onAfterSubmitForm) {
          onAfterSubmitForm()
        }

        const profileState = profileSelectors.entity(store.getState())

        if ((rubric && rubric?.id !== 4) || profileState.secondPhone) {
          setTimeout(() => {
            messengersDialog.openDialog()
          }, 600)
        } else {
          parentFormDialog.openDialog()
        }

        await dispatch(eventsMyThunks.fetch({}))
      }
    },
    [
      currentFormValues,
      dispatch,
      exam,
      id,
      messengersDialog,
      parentFormDialog,
      patchProfile,
      registerProfile,
      rubric?.id,
      sendRequestCode,
      store,
      onAfterSubmitForm,
    ]
  )

  const onPhoneConfirmClose = useCallback(() => {
    setNeedConfirmPhone(false)
  }, [setNeedConfirmPhone])

  return {
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    phone: currentFormValues?.phone,
    needConfirmPhone,
    isLoading,
    onPhoneConfirmClose,
    parentFormDialog,
    messengersDialog,
  }
}
